import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BackgroundSliderContainer from '../components/layout/BackgroundSliderContainer'
import { Fade } from 'react-awesome-reveal'

const NotFound = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     file(relativePath: { eq: "huiles/huiles_9.JPG" }) {
  //       childImageSharp {
  //         # Specify the image processing specifications right in the query.
  //         # Makes it trivial to update as your page's design changes.
  //         fluid (maxWidth: 450){
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  // console.log(data)

  return (
    <Container className="my-5" fluid>
      <BackgroundSliderContainer />
      <Fade>
        <Row className="py-5">
          <Col
            className="text-center mx-auto py-3"
            style={{
              borderRadius: '30px',
              backgroundColor: 'rgba(255,255,255,0.85)',
              maxWidth: '1200px',
            }}
          >
            <h1
              style={{
                color: '#f0ad4e',
                fontSize: 'calc(2rem + 7vw)',

              }}
            >
              Page non trouvée ! (Erreur 404)
            </h1>
            
          </Col>
        </Row>
        
      </Fade>
    </Container>
  )
}

export default NotFound
