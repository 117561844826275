import React, { Component } from 'react'
import Layout from '../components/layout/Layout'
import NotFound from '../components/NotFound'

//import '../components/page.css'

class App extends Component {
  render() {
    return (
      <Layout backgroundImage >
        <NotFound />
      </Layout>
    )
  }
}

export default App
