import { graphql, useStaticQuery } from 'gatsby'
import BackgroundSlider from 'gatsby-image-background-slider'
import React from 'react';   

/**
 * Uses GatsbyImage and GraphQL to return all images.
 * @see https://www.orangejellyfish.com/blog/a-comprehensive-guide-to-images-in-gatsby/
 */
const BackgroundSliderContainer = () => {
  return (
    <BackgroundSlider   
    className = "bgImage" 
 
      query={useStaticQuery(graphql`
        query {
          backgrounds: allFile(
            filter: { sourceInstanceName: { eq: "background" } }
          ) {
            nodes {
              relativePath
              childImageSharp {
                fluid(maxHeight: 1000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      `)}
    />
  )
};

export default BackgroundSliderContainer
